import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    /*登录界面*/
    {
      path: '/loginPage',
      name: 'loginPage',
      component: () => import('@/views/LoginPage.vue')
    },
    {
      path: '/',
      redirect: '/loginPage'
    },

    /*个人中心*/
    {
      path: '/person',
      name: 'person',
      component: () => import('@/views/Person.vue')
    },
    /*管理员界面*/
    {
      path: '/stkadmin',
      name: 'stkadmin',
      component: () => import('@/views/Main.vue'),
      children: [
        /*管理员主頁*/
        {
          path: '',
          name: 'stkadminHome',
          component: () => import('@/views/stkadmin/StkadminHome.vue')
        },
        /*属性管理*/
        {
          path: '/attributeManagement',
          name: 'airportManagement',
          component: () => import('@/views/AttributeManagement.vue'),
          children: [
            {
              path: 'attributeList',
              name: 'attributeList',
              component: () => import('@/components/attributeManagement/AttributeList.vue')
            },
            {
              path: 'attributeLog',
              name: 'attributeLog',
              component: () => import('@/components/attributeManagement/AttributeLog.vue')
            }
          ]
        },
        /*徽章管理*/
        {
          path: '/badgeManagement',
          name: 'badgeManagement',
          component: () => import('@/views/BadgeManagement.vue'),
          children: [
            {
              path: 'badgeList',
              name: 'badge',
              component: () => import('@/components/badgeManagement/BadgeList.vue')
            },
            {
              path: 'badgeLog',
              name: 'badgeLog',
              component: () => import('@/components/badgeManagement/BadgeLog.vue')
            }
          ]
        },
        /*商城管理*/
        {
          path: '/mallManagement',
          name: 'mallManagement',
          component: () => import('@/views/MallManagement.vue'),
          children: [
            {
              path: 'commodityManagement',
              name: 'commodityManagement',
              component: () => import('@/components/mallManagement/CommodityManagement.vue')
            },
            {
              path: 'addCommodity',
              name: 'addCommodity',
              component: () => import('@/components/mallManagement/AddCommodity.vue'),
              meta: {title: '新增商品'},
              hidden:true
            },
            {
              path: 'exchangeManagement',
              name: 'exchangeManagement',
              component: () => import('@/components/mallManagement/ExchangeManagement.vue')
            },
            {
              path: 'orderManagement',
              name: 'orderManagement',
              component: () => import('@/components/mallManagement/OrderManagement.vue')
            },
            {
              path: 'orderDetail',
              name: 'orderDetail',
              component: () => import('@/components/mallManagement/OrderDetail.vue'),
              meta: {title: '订单详情'},
              hidden:true
            },
            {
              path: 'pointsManagement',
              name: 'pointsManagement',
              component: () => import('@/components/mallManagement/PointsManagement.vue')
            }
          ]
        },
        /*虚拟服饰管理*/
        {
          path: '/clothesManagement',
          name: 'clothesManagement',
          component: () => import('@/views/ClothesManagement.vue'),
          children: [
            {
              path: 'clothesList',
              name: 'clothesList',
              component: () => import('@/components/clothesManagement/ClothesList.vue')
            },
            {
              path: 'addClothes',
              name: 'addClothes',
              component: () => import('@/components/clothesManagement/AddClothes.vue'),
              meta: {title: '新增服饰'},
              hidden:true
            }
          ]
        },
        /*权限管理*/
        {
          path: '/rightManagement',
          name: 'rightManagement',
          component: () => import('@/views/RightManagement.vue'),
          children: [
            {
              path: 'userManagement',
              name: 'userManagement',
              component: () => import('@/components/rightManagement/UserManagement.vue')
            },
            {
              path: 'roleManagement',
              name: 'roleManagement',
              component: () => import('@/components/rightManagement/RoleManagement.vue')
            },
            {
              path: 'advertiserManagement',
              name: 'advertiserManagement',
              component: () => import('@/components/rightManagement/AdvertiserManagement.vue')
            },
            {
              path: 'advertiserDetail',
              name: 'advertiserDetail',
              component: () => import('@/components/rightManagement/AdvertiserDetail.vue'),
              meta: {title: '广告主詳情'},
              hidden:true
            },
            {
              path: 'addAdvertiser',
              name: 'addAdvertiser',
              component: () => import('@/components/rightManagement/AddAdvertiser.vue'),
              meta: {title: '添加广告主'},
              hidden:true
            },
            {
              path: 'editAdvertiser',
              name: 'editAdvertiser',
              component: () => import('@/components/rightManagement/EditAdvertiser.vue'),
              meta: {title: '編輯广告主'},
              hidden:true
            }
          ]
        },
        /*任务管理*/
        {
          path: '/taskManagement',
          name: 'taskManagement',
          component: () => import('@/views/TaskManagement.vue'),
          children: [
            {
              path: 'taskList',
              name: 'taskList',
              component: () => import('@/components/taskManagement/TaskList.vue')
            },
            {
              path: 'addTask',
              name: 'addTask',
              component: () => import('@/components/taskManagement/AddTask.vue'),
              meta: {title: '新增任務'},
              hidden:true
            },
            {
              path: 'edutaskMonitor',
              name: 'edutaskMonitor',
              component: () => import('@/components/taskManagement/EdutaskMonitor.vue')
            },
            {
              path: 'edutaskDetail',
              name: 'edutaskDetail',
              component: () => import('@/components/taskManagement/EdutaskDetail.vue'),
              meta: {title: '学生任务详情'},
              hidden:true
            },
            {
              path: 'checkinAuditor',
              name: 'checkinAuditor',
              component: () => import('@/components/taskManagement/CheckinAuditor.vue')
            }
          ]
        },
        /*學生管理*/
        {
          path: '/studentManagement',
          name: 'studentManagement',
          component: () => import('@/views/StudentManagement.vue'),
          children: [
            {
              path: 'studentCertificationReview',
              name: 'studentCertificationReview',
              component: () => import('@/components/studentManagement/StudentCertificationReview.vue')
            },
            {
              path: 'studentInformationManagement',
              name: 'studentInformationManagement',
              component: () => import('@/components/studentManagement/StudentInformationManagement.vue')
            }
          ]
        },
        /*优惠券管理*/
        {
          path: '/couponManagement',
          name: 'couponManagement',
          component: () => import('@/views/CouponManagement.vue'),
          children: [
            {
              path: 'couponList',
              name: 'couponList',
              component: () => import('@/components/couponManagement/CouponList.vue')
            }
          ]
        },
        /*广告管理*/
        {
          path: '/adminAdManagement',
          name: 'adminAdManagement',
          component: () => import('@/views/AdminAdManagement.vue'),
          children: [
            {
              path: 'adList',
              name: 'adList',
              component: () => import('@/components/adminAdManagement/AdList.vue')
            },
            {
              path: 'addAd',
              name: 'addAd',
              component: () => import('@/components/adminAdManagement/AddAd.vue')
            },
            {
              path: 'adMonitor',
              name: 'adMonitor',
              component: () => import('@/components/adminAdManagement/AdMonitor.vue')
            },
            {
              path: 'auditRecord',
              name: 'auditRecord',
              component: () => import('@/components/adminAdManagement/AuditRecord.vue')
            }

          ]
        },
        /*答題类任务管理*/
        {
          path: '/quizManagement',
          name: 'quizManagement',
          component: () => import('@/views/QuizManagement.vue'),
          children: [
            {
              path: 'questionBankManagement',
              name: 'questionBankManagement',
              component: () => import('@/components/quizManagement/QuestionBankManagement.vue')
            },
            {
              path: 'addQuestion',
              name: 'addQuestion',
              component: () => import('@/components/quizManagement/AddQuestion.vue'),
              meta: {title: '新增试题'},
              hidden:true
            },
            {
              path: 'questionManagement',
              name: 'questionManagement',
              component: () => import('@/components/quizManagement/QuestionManagement.vue')
            }
          ]
        },

      ]
    },

     /*广告商界面*/
    {
      path: '/advertiser',
      name: 'advertiser',
      component: () => import('@/views/Main.vue'),
      children: [
        /*广告商商主页*/
        {
          path: '',
          name: 'advertiserHome',
          component: () => import('@/views/advertiser/AdvertiserHome.vue')
        },
        //广告商个人中心
        {
          path: 'person',
          name: 'person',
          component: () => import('@/views/Person.vue')
        },
        //广告商钱包
        {
          path: 'myWallet',
          name: 'myWallet',
          component: () => import('@/components/advertiserAdManagement/MyWallet.vue'),
          meta: {title: '广告商钱包'},
          hidden:true
        },
        /*优惠券管理*/
        {
          path: '/couponManagement',
          name: 'couponManagement',
          component: () => import('@/views/CouponManagement.vue'),
          children: [
            {
              path: 'advertiserCouponList',
              name: 'advertiserCouponList',
              component: () => import('@/components/couponManagement/advertiser/AdvertiserCouponList.vue')
            },
            {
              path: 'couponMonitor',
              name: 'couponMonitor',
              component: () => import('@/components/couponManagement/CouponMonitor.vue')
            },
            {
              path: 'couponVerification',
              name: 'couponVerification',
              component: () => import('@/components/couponManagement/advertiser/CouponVerification.vue')
            },
            {
              path: 'addCoupon',
              name: 'addCoupon',
              component: () => import('@/components/couponManagement/AddCoupon.vue'),
              meta: {title: '新增優惠券'},
              hidden:true
            },
            {
              path: 'couponDetail',
              name: 'couponDetail',
              component: () => import('@/components/couponManagement/CouponDetail.vue'),
              meta: {title: '優惠券詳情'},
              hidden:true
            }
          ]
        },
        /*广告管理*/
        {
          path: '/advertiserAdManagement',
          name: 'advertiserAdManagement',
          component: () => import('@/views/advertiser/AdvertisementManagement.vue'),
          children: [
            {
              path: 'addAd',
              name: 'addAd',
              component: () => import('@/components/advertiserAdManagement/AddAd.vue')
            },
            {
              path: 'adMonitor',
              name: 'adMonitor',
              component: () => import('@/components/advertiserAdManagement/AdMonitor.vue')
            },
            {
              path: 'auditRecord',
              name: 'auditRecord',
              component: () => import('@/components/advertiserAdManagement/AuditRecord.vue')
            },
          ]
        },
      ]
    },
  ]
})

export default router
