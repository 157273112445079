<script lang="ts" setup>

</script>

<template>
  <router-view/>


</template>

<style lang="scss" scoped>
#app {
  width: 100vw;
  height: 100vh;
  background-color: #03050C;
  // overflow: hidden;
}
</style>
