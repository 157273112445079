import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from "@/utils/request";
import ElementPlus from 'element-plus' //全局引入
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './theme/index.scss'
import locale from "element-plus/es/locale/lang/zh-cn";//需要新加的代码
import VForm3 from 'vform3-builds'  //引入VForm3库
import 'vform3-builds/dist/designer.style.css'  //引入VForm3样式
import echarts from "echarts"; // npm install echarts --save
//import VCharts from 'v-charts'


const app = createApp(App)
const VueECharts = require('vue-echarts')
app.component('v-chart', VueECharts)
app.config.globalProperties.axios = request // 将 Axios 添加到 Vue 应用程序的全局属性上
app.config.globalProperties.$echarts = echarts;
app.use(router)
app.use(store)
app.use(ElementPlus, {locale});//需要改变的地方，加入locale即可
app.use(VForm3)
//app.use(VCharts)
window.axios = request

app.mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}


