import { createStore } from 'vuex'

export default createStore({
  getters: {
  },
  actions: {
  },
  modules: {
  },
  state () {
    return {
      tabsList: [
        /*{
            menuPath: '/stkadmin/userManage',
            menuName: '用户管理'
        }*/
      ]//面包屑
    }
  },
  mutations: {
    /*更新面包屑数据*/
    selectMenu(state,val) {
      console.log(val,'val')
      //判断添加的数据是否已存在
      const index = state.tabsList.findIndex(item => item.menuName === val.menuName)//如果存在，返回当前数据索引
      if(index === -1) {
        state.tabsList.push(val)
      }

    }
  }
})
